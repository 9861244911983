<template>
  <div>
    <router-link class="come-back" to="/admin/announcement/">
      <div></div>
      Вернуться назад
    </router-link>

    <h2 class="title_categories">
      {{ isEdit ? `Редактирование анонса №${$route.params.id}` : 'Создание анонса' }}
    </h2>
    <tricolor-line class="card_containet__tricolor"/>

    <form @submit.prevent="createAnnouncement" ref="createAnnouncement">
      <div class="inputs__container">
        <div class="content-sb">
          <p>Заголовок<span>*</span></p>
          <input required placeholder="Введите заголовок" v-model="announcementData.title" type="text" name="title" class="input blue">
        </div>

        <div class="content-sb">
          <p>Заголовок (Англ)</p>
          <input placeholder="Введите заголовок" v-model="announcementData.title_eng" type="text" name="title_eng" class="input blue">
        </div>

        <div class="content-sb">
          <p>Описание<span>*</span></p>
          <input required placeholder="Введите описание" v-model="announcementData.description" type="text" name="description" class="input blue">
        </div>

        <div class="content-sb">
          <p>Описание (Англ)</p>
          <input placeholder="Введите описание" v-model="announcementData.description_eng" type="text" name="description_eng" class="input blue">
        </div>

        <div class="content-sb">
          <p>Дата<span>*</span></p>
          <input required placeholder="Введите дату" v-model="announcementData.date" type="datetime-local" name="date_time" class="input blue">
        </div>

        <div class="content-sb">
          <p>Признак главного анонса<span>*</span></p>
          <select name="main_page" v-model="announcementData.main_page" class="input blue">
            <option value="0" selected>Нет</option>
            <option value="1">Да</option>
          </select>
        </div>

        <div class="content-sb">
          <p>Архив<span>*</span></p>
          <select name="main_page" v-model="announcementData.archival" class="input blue">
            <option value="0" selected>Нет</option>
            <option value="1">Да</option>
          </select>
        </div>

        <div class="content-sb">
          <p>Рубрика<span>*</span></p>
          <select name="rubric" v-model="announcementData.rubric" class="input blue">
            <option v-for="(rubric,index) in getRubrics" :value="rubric.id" :key="`rubric${index}`" :selected="index === 0">
              {{ rubric.title }}
            </option>
          </select>
        </div>

        <div class="content-sb">
          <p>Теги</p>
          <tags-list-search :tags="getTagsList" @setTags="setTags"/>
        </div>

        <div class="content-sb">
          <p>Превью<br/><span @click="deletePreview()" v-if="previewBlob || announcementData.preview_photo">Удалить</span></p>
          <custom-drop-zone :name="'preview_photo'"
                            @setFile="setFile($event, ['announcementData','preview_photo'])"
                            ref="previewPhoto"
                            :uploaded-blob="previewBlob"/>
        </div>
      </div>

      <div class="content-blocks__container">
        <div v-for="(contentBlock, index) in contentBlocks" :key="`contentBlock${index}`" class="content-block">
          <header>
            <h4>{{ contentBlock.title }}</h4>

            <img src="/assets/icons/close.svg" @click="deleteContentBlock(index)">
          </header>

          <div class="data-card">
            <component :is="contentBlock.component"
                       :index="index"
                       :content="contentBlock"
                       @setBlockContentData="setBlockContentData($event)"
                       @deleteFile="deleteFile($event)"/>
          </div>
        </div>
      </div>

      <div class="add-content-block__container">
        <div class="row">
          <content-block-card v-for="(contentBlockType,index) in getContentBlockTypes"
                              :key="`contentBlockType${index}`"
                              :content-block-type="contentBlockType"
                              :index="index"
                              :current-content-block-type="currentContentBlockType"
                              @setContentBlockType="setContentBlockType($event)"/>
        </div>

        <div class="btn-blue" @click="addContentBlock">
          <img src="/assets/icons/circle_plus.svg">
          Добавить блок
        </div>
      </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/news" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import ContentBlockCard from '@/components/Admin/news/ContentBlockCard'
import TextContentBlock from '@/components/Admin/news/TextContentBlock'
import RightPicContentBlock from '@/components/Admin/news/RightPicContentBlock'
import LeftPicContentBlock from '@/components/Admin/news/LeftPicContentBlock'
import TwoPicContentBlock from '@/components/Admin/news/TwoPicContentBlock'
import GalleryContentBlock from '@/components/Admin/news/GalleryContentBlock'
import CustomDropZone from '@/components/Admin/CustomDropZone'
import TagsListSearch from '@/components/news/TagsListSearch'

export default {
  name: 'CreateAnnouncement',

  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      previewBlob: null,
      announcementData: {
        title: null,
        title_eng: null,
        description: null,
        description_eng: null,
        date: null,
        preview_photo: null,
        main_page: 0,
        archival: 0,
        rubric: 1,
        tags_list: null
      },
      contentBlocks: [

      ],
      currentContentBlockType: 0
    }
  },

  watch: {
    getCurrentAnnouncement () {
      const dataFromServer = this.getCurrentAnnouncement

      this.announcementData = {
        title: dataFromServer.title,
        title_eng: dataFromServer.title_eng,
        description: dataFromServer.description,
        description_eng: dataFromServer.description_eng,
        date: dataFromServer.date.split(' ').join('T'),
        main_page: +dataFromServer.main_page,
        archival: +dataFromServer.archival,
        rubric: dataFromServer.rubric,
        tags_list: dataFromServer.tags_list ? dataFromServer.tags_list.join('/') : ''
      }

      if (dataFromServer.preview_photo) {
        const previewPhoto = dataFromServer.preview_photo.split('/')
        this.announcementData.preview_photo = previewPhoto[previewPhoto.length - 1]
        this.previewBlob = dataFromServer.preview_photo
      }

      this.contentBlocks = dataFromServer.content_blocks.map((block) => {
        let component = block.content_block_type.code.toLowerCase().split('_')

        component = component.reduce((componentName, word) => {
          return componentName + (word && word.charAt(0).toUpperCase() + word.slice(1))
        }, '')

        return {
          ...block,
          image: null,
          text: block.text,
          text_eng: block.text_eng,
          blob: block.image,
          type: block.content_block_type.id,
          component: component + 'ContentBlock'
        }
      })

      this.$forceUpdate()
    }
  },

  created () {
    if (this.isEdit) {
      this.$store.dispatch('getAdminAnnouncementById', this.$route.params.id)
    }

    this.$store.dispatch('getAnnouncements')
    this.$store.dispatch('getRubrics')
    this.$store.dispatch('getContentBlockTypes')
  },

  methods: {
    createAnnouncement () {
      const formData = new FormData()

      for (const key in this.announcementData) {
        if (this.announcementData[key] !== null) {
          formData.append(key, this.announcementData[key])
        }
      }

      this.contentBlocks.forEach((contentBlock, index) => {
        const contentBlockForJson = {
          index: index,
          text: contentBlock.text,
          text_eng: contentBlock.text_eng,
          type: contentBlock.type,
          image: null,
          content: null
        }

        if (contentBlock.id) {
          contentBlockForJson.id = contentBlock.id
        }

        let images = []

        if (contentBlock.blob) {
          contentBlock.blob.forEach((blob, i) => {
            if (blob) {
              const name = blob.split('/')
              images.push({
                index: i,
                image: name[name.length - 1]
              })
              // formData.append(`image_content[${index}][${i}]`, name[name.length - 1])
            }
          })
        }

        if (contentBlock.image) {
          contentBlock.image.forEach((image, i) => {
            if (image) {
              images.push({
                index: i,
                image: image
              })
              // formData.append(`image_content[${index}][${i}]`, image)
            }
          })
        }

        images = images.sort((a, b) => {
          return a.index - b.index
        })

        images.forEach((image) => {
          formData.append(`image_content[${index}][${image.index}][image]`, image.image)
          formData.append(`image_content[${index}][${image.index}][index]`, image.index)
        })

        formData.append('content[]', JSON.stringify(contentBlockForJson))
      })

      console.debug(...formData)

      if (!this.isEdit) {
        this.$store.dispatch('createAnnouncement', formData)
      } else {
        formData.append('_method', 'PUT')
        this.$store.dispatch('updateAnnouncement', {
          data: formData,
          id: this.$route.params.id
        })
      }
    },

    setContentBlockType (index) {
      this.currentContentBlockType = index
    },

    addContentBlock () {
      const type = this.getContentBlockTypes[this.currentContentBlockType]

      let component = type.code.toLowerCase().split('_')

      component = component.reduce((componentName, word) => {
        return componentName + (word && word.charAt(0).toUpperCase() + word.slice(1))
      }, '')

      this.contentBlocks.push({
        text: null,
        text_eng: null,
        type: type.id,
        component: component + 'ContentBlock',
        image: null,
        title: type.title
      })
    },

    deleteContentBlock (index) {
      this.contentBlocks.splice(index, 1)
    },

    deletePreview () {
      this.announcementData.preview_photo = null
      this.previewBlob = null
      if (this.$refs.previewPhoto) {
        this.$refs.previewPhoto.removeFile()
      }
      this.$forceUpdate()
    },

    setBlockContentData (data) {
      console.log(data)
      if (data.data.image) {
        if (data.data.image.length > 1) {
          data.data.image.forEach((image, index) => {
            if (image && this.contentBlocks[data.index].blob) {
              this.contentBlocks[data.index].blob[index] = null
            }
          })
        } else {
          this.contentBlocks[data.index].blob = null
        }
      }
      Object.entries(data.data).forEach(([key, value]) => {
        this.contentBlocks[data.index][key] = value
      })
    },

    deleteFile (indexes) {
      this.contentBlocks[indexes.contentBlockIndex].blob.splice(indexes.fileIndex, 1)
    },

    setFile (file, key) {
      // this.newsData.preview_photo = file
      if (key.length === 1) {
        this[key[0]] = file
      } else if (key.length === 2) {
        this.previewBlob = null
        this[key[0]][key[1]] = file
      }
    },

    setTags (tags) {
      this.announcementData.tags_list = tags.join('/')
    }
  },

  computed: {
    getRubrics () {
      return this.$store.getters.getRubrics
    },

    getContentBlockTypes () {
      return this.$store.getters.getContentBlockTypes.filter((type) => type.code !== 'EMBEDDED')
    },

    getCurrentAnnouncement () {
      return this.$store.getters.getCurrentAdminAnnouncement
    },

    getTagsList () {
      if (this.announcementData.tags_list) {
        return this.announcementData.tags_list.split('/').filter(tag => !!tag)
      } else {
        return []
      }
    }
  },

  components: {
    TagsListSearch,
    CustomDropZone,
    TextContentBlock,
    RightPicContentBlock,
    LeftPicContentBlock,
    TwoPicContentBlock,
    ContentBlockCard,
    GalleryContentBlock,
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  .btns-create-form {
    border-top: 1px solid #D5D6D9;
    padding-top: 2.5rem;
    margin-top: 2.5rem;
  }

  .content-sb {
    margin-bottom: 1.25rem;

    span {
      color: red;
    }
  }

  .select, .input {
    width: 33.75rem;
  }

  .inputs__container {
    margin-bottom: 2rem;
    border-bottom: 1px solid #D5D6D9;
  }

  .content-blocks__container {
    display: flex;
    flex-direction: column;

    > .content-block {
      margin-bottom: 1.875rem;

      header {
        margin-bottom: .75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h4 {
          font-size: .875rem;
          line-height: 1.375rem;
          color: $mainFontColor;
        }

        > img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
          cursor: pointer;
        }
      }

      .data-card {
        background: white;
        border: 1px solid #EBF3FF;
        box-sizing: border-box;
        box-shadow: 0 .9375rem 1.5625rem rgba(189, 205, 221, 0.15);
        border-radius: .5rem;
        padding: 1.25rem;
        width: 100%;
        min-height: 8.875rem;
      }
    }
  }

  .add-content-block__container {
    background-color: $neutral-placeholder;
    width: 100%;
    padding: 1.25rem;
    border-radius: .187rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: .5rem;

    &:after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $neutral-placeholder;
      top: 0;
      left: 50%;
      transform: translate(-50%,-50%) rotate(45deg);
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    > .btn-blue > img {
      width: 1.125rem;
      height: 1.125rem;
      object-fit: contain;
      object-position: center;
      margin-right: .5rem;
    }
  }
</style>
